export function registerCustomElement(
  tagName: string,
  elementClass: CustomElementConstructor,
  options?: ElementDefinitionOptions,
) {
  if (typeof window === 'undefined') return
  if (!window.customElements.get(tagName)) {
    // Disabling these lint rules because they are not compatible with dynamic custom element registration
    // eslint-disable-next-line wc/tag-name-matches-class
    window.customElements.define(tagName, elementClass, options)
  }
}
